export const FIND_ACTIONS = {
  ADD_USER: "ADD_USER",
  REMOVE_USER: "REMOVE_USER",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  TOGGLE_ADD_USER_MODAL_TO: "TOGGLE_ADD_USER_MODAL_TO",
  TOGGLE_SETTING_MODAL_TO: "TOGGLE_SETTING_MODAL_TO",
};

export const ANALYZE_ACTIONS = {
  UPDATE_USERNAMES_DATA: "UPDATE_USERNAMES_DATA",
};

export const FREE_ACTIONS = {
  ADD_USER: "FREE_ADD_USER",
  UPDATE_FILTER_OPTIONS: "UPDATE_FILTER_OPTIONS",
};
